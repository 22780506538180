import React, {Component} from 'react';
import './Menu.scss';
import {Link, animateScroll as scroll} from 'react-scroll';
import ToggleMenu from "../ToggleMenu/ToggelMenu";
import {Logo} from "../UI/Icons/Icons";
import Container from "../UI/Container/Container";
import {Button} from "../UI/Forms/Buttons/Buttons";
import DropDownLng from "../DropDownLng/DropDownLng.jsx";
import {withI18n} from "react-i18next";
import DropDownLngMobile from "../DropDownLngMobile/DropDownLngMobile";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headerClass: '',
            mobileMenuOpen: false
        };

        this.openMobileMenu = this.openMobileMenu.bind(this);
        this.mobileMenuClose = this.mobileMenuClose.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    mobileMenuClose() {
        this.setState({mobileMenuOpen: false})
    }

    openMobileMenu() {
        this.setState({mobileMenuOpen: !this.state.mobileMenuOpen});
        //
        const body = document.getElementsByTagName('body')[0];

        this.state.mobileMenuOpen ? body.style.overflow = 'visible' : body.style.overflow = 'hidden';
    }

    scrollTop() {
        scroll.scrollToTop();
    }


    render() {
        const {t,shown_prices} = this.props;
        const {mobileMenuOpen} = this.state;

        return (

            <div className={"menu"}>
                <Container>
                    <div className="menu__wrapper">
                        <div className="menu__logo" onClick={this.scrollTop}>
                            <Logo/>
                        </div>

                        <div className="menu__list">
                            <ul>
                                <Link to="about" spy={true} smooth={true} offset={-80} duration={500}>
                                    <li className="active menu__item">{t('Про готель')}</li>
                                </Link>
                                <Link to="rooms" spy={true} smooth={true} offset={-60} duration={500}>
                                    <li className="menu__item">{t('Номери')}</li>
                                </Link>
                                <Link to="services" spy={true} smooth={true} offset={-90} duration={500}>
                                    <li className="menu__item">{t('Сервіси')}</li>
                                </Link>
                                {shown_prices ? <Link to="prices" spy={true} smooth={true} offset={-50} duration={500}>
                                    <li className="menu__item">{t('Ціни')}</li>
                                </Link> : null}
                                <Link to="location" spy={true} smooth={true} offset={-200} duration={500}>
                                    <li className="menu__item">{t('Розташування')}</li>
                                </Link>
                                 <Link to="contacts" spy={true} smooth={true} offset={-200} duration={500}>
                                    <li className="menu__item">{t('Контакти')}</li>
                                </Link>


                                {/*<Link to="contacts" spy={true} smooth={true} duration={500}>*/}
                                    {/*<li className="menu__item">{t('Контакти')}</li>*/}
                                {/*</Link>*/}
                            </ul>

                            <Link to="reservation" spy={true} offset={-90} smooth={true} duration={500}
                                  className="menu__button">
                                <Button>{t('Бронювати')}</Button>
                            </Link>
                            <DropDownLng/>

                        </div>

                        <ToggleMenu toggle={mobileMenuOpen} onClick={this.openMobileMenu}/>


                        <div className={"mobile__menu " + (mobileMenuOpen ? "open" : "")}>
                            <div className="mobile__header">
                                <DropDownLngMobile />
                            </div>
                            <div className="mobile__item-list">
                                <ul>
                                    <Link to="about" spy={true} smooth={true} offset={-80} duration={500}>
                                        <li className="active" onClick={this.openMobileMenu}>{t('Про готель')}</li>

                                    </Link>
                                    <Link to="rooms" spy={true} smooth={true} offset={-80} duration={500}
                                          onClick={this.openMobileMenu}>
                                        <li>{t('Номери')}</li>

                                    </Link>
                                    <Link to="services" spy={true} smooth={true} offset={-100} duration={500}
                                          onClick={this.openMobileMenu}>
                                        <li>{t('Сервіси')}</li>

                                    </Link>
                                    {shown_prices ? <Link to="prices" spy={true} smooth={true} offset={-100} duration={500}
                                          onClick={this.openMobileMenu}>
                                        <li>{t('Ціни')}</li>

                                    </Link> : null}
                                    <Link to="location" spy={true} smooth={true} offset={-60} duration={500}
                                          onClick={this.openMobileMenu}>
                                        <li>{t('Розташування')}</li>
                                    </Link>
                                    <Link to="contacts" spy={true} smooth={true} offset={-80} duration={500}  onClick={this.openMobileMenu}>
                                        <li className="menu__item">{t('Контакти')}</li>
                                    </Link>
                                    {/*<Link to="contacts" spy={true} smooth={true} duration={500}*/}
                                          {/*onClick={this.openMobileMenu}>*/}
                                        {/*<li>{t('Контакти')}</li>*/}
                                    {/*</Link>*/}
                                </ul>
                            </div>

                            <div className="mobile__reservation">
                                <Link onClick={this.openMobileMenu} to="reservation" spy={true} offset={-50}
                                      smooth={true} duration={500}
                                      className="mobile__link"><Button>{t('Бронювати')}</Button></Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default withI18n()(Menu);