import React, {Component} from "react";
import Datepicker from "../UI/Forms/Datepicker/Datepicker.jsx";
import "./Reservation.scss"
import * as moment from 'moment';
import {Button} from "../UI/Forms/Buttons/Buttons";
import Select from "../UI/Forms/Select/Select.jsx";
import {withI18n} from "react-i18next";
import {withRouter} from "react-router-dom";


class Reservation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectValueChildren: '0',
            selectValueAdults: '1',
            checkIn: moment(),
            checkOut: moment().add('2', 'd'),
            // closeConfirm: true,
        }
    }


    selectHandlerAdults = (e) => {
        this.setState({selectValueAdults: e.target.value})
    };

    selectHandlerChilds = (e) => {
        this.setState({selectValueChildren: e.target.value})
    };

    handleCheckIn = (date) => {

        // moment as wrapper for do not change date object
        const checkOutDate = moment(date).add('1', 'd');
        this.setState({checkIn: date, checkOut: checkOutDate})

    };

    handleCheckOut = (date) => {
        this.setState({checkOut: date})
    };

    submit = () => {
        const {selectValueChildren, selectValueAdults, checkIn, checkOut} = this.state;
        const arrival = moment(checkIn).format('YYYY-MM-DD');
        const departure = moment(checkOut).format('YYYY-MM-DD');
        const {lng} = this.props.match.params;

        const language = lng ? `/${lng}` : '';

        let childrenArrray = [];

        if(selectValueChildren){
            for(let i = 0; i < selectValueChildren; i++){
                childrenArrray.push("kids[]=6")
            }
        }

        let joinKids = childrenArrray.join("&");
        window.open(
            `https://bukovel24.com${language}/hotels/bukovel-apart?arrival_date=${arrival}&departure_date=${departure}&adults=${selectValueAdults}&${joinKids}`,
            '_blank'
        );

    };

    render() {

        const {t, name} = this.props;
        return (
            <div className="reservation" name={name}>

                <div className="reservation__wrapper">
                    <div className="reservation__item">
                        <label className="reservation__label">{t('Дата заїзду')}</label>
                        <Datepicker
                            selected={this.state.checkIn}
                            onSelect={this.handleCheckIn}
                            dateFormat="DD.MM.YYYY"
                            minDate={new Date()}
                            customInput={<button
                                className="datepicker__button">{moment(this.state.checkIn).format("DD.MM.YYYY")}</button>}
                        />
                    </div>

                    <div className="reservation__item">
                        <label className="reservation__label">{t('Дата виїзду')}</label>
                        <Datepicker
                            dateFormat="DD.MM.YYYY"
                            selected={this.state.checkOut}
                            onSelect={this.handleCheckOut}
                            minDate={moment(this.state.checkIn).add('1', 'days')}
                            customInput={<button
                                className="datepicker__button">{moment(this.state.checkOut).format("DD.MM.YYYY")}</button>}
                        />
                    </div>

                    <div className="reservation__item">
                        <label className="reservation__label">{t('Дорослих')}</label>
                        <Select className="reservation__select" onChange={this.selectHandlerAdults}>
                            <option checked value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </Select>
                    </div>

                    <div className="reservation__item">
                        <label className="reservation__label">{t('Дітей')}</label>
                        <Select className="reservation__select" onChange={this.selectHandlerChilds}>
                            <option checked value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </Select>
                    </div>

                    <div className="reservation__item">
                        <Button onClick={this.submit}>{t('Бронювати')}</Button>
                    </div>
                </div>
            </div>
        )

    }
}

export default withRouter(withI18n()(Reservation));