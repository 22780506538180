import React from "react";
import "./Services.scss";
import TitleWithDescription from "../UI/TitleWithDescription/TitleWithDescription";

const Services = (props) => {
  const {services = [], service_title = {}} = props;
  const {title, descr} = service_title;
  return (
    <div className="services" name={props.name}>
      <TitleWithDescription
        title={title}
        description={descr}
        center
      />

      <div className="services__wrapper">

        {services.map((item, index) => {
          return (
            <div className="services__item" key={index}>
              <img src={item.img} className="services__image"></img>
              <div className="services__text">
                <h2>{item.title}</h2>
                {/*<div dangerouslySetInnerHTML={{__html: item.descr}} className="services__description"></div>*/}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
};


export default Services;