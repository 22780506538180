import React, {Component} from "react";
import "./Location.scss";
import TitleWithDescription from "../UI/TitleWithDescription/TitleWithDescription";

class Location extends Component {

  render() {
    const {location = {}, name} = this.props;

    return (
      <div className="location" name={name}>
        <div className="location__left">
          <TitleWithDescription
            title={location.title}
            description={location.descr}
          />
        </div>

          <div className="location__right">
            <a
              href="https://www.google.com.ua/maps/place/VIP-%D1%80%D0%B5%D0%B7%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D1%96%D1%8F/@48.3557294,24.4105285,19z/data=!3m1!4b1!4m9!3m8!1s0x4737187ad53f3119:0x6f000065f9747b8d!5m2!4m1!1i2!8m2!3d48.3557285!4d24.4117462!16s%2Fg%2F1q2_d2xlk?entry=ttu"
              target="_blank">
              <div className="location__img"
                   style={{backgroundImage: 'url("/images/background/location.jpg")'}}></div>
            </a>
          </div>
      </div>
    )
  }
}


export default Location;