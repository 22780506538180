import React from "react";


const IconFaceBook = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 2.00024H12.5C10.567 2.00024 9 3.56724 9 5.50024V8.00024H7V11.0002H9V18.0002H12V11.0002H15V8.00024H12V6.00024C12 5.44824 12.448 5.00024 13 5.00024H15V2.00024ZM2 0.000244141H16C17.104 0.000244141 18 0.896244 18 2.00024V16.0002C18 17.1052 17.104 18.0002 16 18.0002H2C0.896 18.0002 0 17.1052 0 16.0002V2.00024C0 0.896244 0.896 0.000244141 2 0.000244141Z" fill="white"/>
    </svg>
  )
}

export default IconFaceBook