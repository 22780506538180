import React, {Component} from "react";
import "./Rooms.scss";
import Slider from "react-slick";
import LinkPrefix from "../UI/LinkPrefix/LinkPrefix.jsx";
import TitleWithDescription from "../UI/TitleWithDescription/TitleWithDescription";


let dragging = false;

class Rooms extends Component {

  render() {
    const {props} = this;
    const {rooms = [], room_title = {}} = props;
    const {descr, title} = room_title;

    const settings = {
      dots: true,
      centerMode: true,
      infinite: true,
      centerPadding: "0",
      slidesToShow: 3,
      speed: 500,
      draggable: false,
      // initialSlide:1,
      beforeChange: () => dragging = true,
      afterChange: () => dragging = false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1.1,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
            dots: true,
            draggable: true
          }
        }]
    };
    console.log("")
    return (
      <div className="room" name={props.name}>

        <TitleWithDescription
          title={title}
          description={descr}
          center
        />
        {/*<ContainerLarge>*/}
        <div className="room__wrapper">
          {rooms.length ? <div className="room__carousel">
            <Slider {...settings}>
              {rooms.map((item, index) => {
                return (
                  <div key={index}>
                    <LinkPrefix to={`/rooms/${item.id}`}
                                onClick={(e) => dragging && e.preventDefault()}>
                      <div className="room__item">
                        <div className="room__text">

                          <h3>{item.name}</h3>
                          <div className="room__descr" dangerouslySetInnerHTML={{__html: item.descr}}></div>
                        </div>
                        <div className="room__image" style={{backgroundImage: `url('${item.home_img}')`}}></div>
                      </div>
                    </LinkPrefix>

                  </div>
                )
              })}
            </Slider>
          </div> : null}
        </div>
        {/*</ContainerLarge>*/}

      </div>
    )
  }
}

export default Rooms;