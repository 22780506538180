import React, {Component} from "react";
import Slider from "react-slick";
import "./ModalRoom.scss";
import Modal from "../../components/UI/Modal/Modal.jsx";
import {Scrollbars} from 'react-custom-scrollbars';
import {Button} from "../../components/UI/Forms/Buttons/Buttons";
import DoubleCarouselsLayout from "../DoubleCarouselsLayout/DoubleCarouselsLayout.jsx";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Aux from "../../components/hoc/AuxC/AuxC";
import {PreloaderList} from "../../components/UI/Preloader/Preloader.jsx";
import * as moment from "moment";
import MediaQuery from 'react-responsive';
import ModalMobile from "../../components/UI/MobileModal/MobileModal.jsx";
// import {Link} from 'react-scroll';
import {withI18n} from "react-i18next";
import IconSizes from "./icons/IconSizes";
import IconLocation from "./icons/IconLocation";
import IconProps from "./icons/IconProps";

class ModalRoom extends Component {
  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  goBack = (e) => {
    const {lng = ''} = this.props.match.params;
    e.stopPropagation();
    this.props.history.push(`/${lng}`)
    // this.props.history.goBack();
  };

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  render() {
    const {t, isFetching, rooms = [], currentRoom = {}, currentIndex} = this.props;
    const {lng} = this.props.match.params;
    const language = lng ? `/${lng}` : '';

    const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      arrows: false,
      infinite: true,
      initialSlide: currentIndex,
      // infinite:false
    };

    const settingsMobile = {
      slidesToShow: 1.2,
      slidesToScroll: 1,
      arrows: false,
      initialSlide: currentIndex,
    };

    const {name = "", descr = "", prices = [], facilities = [], photos = []} = currentRoom;
    return (
      <Aux>
        {/* FOR MOBILE */}
        <MediaQuery maxWidth={900}>
          {!isFetching && currentRoom ?
            <ModalMobile closePath="/" showButton onClickClose={this.goBack} className={"modal-room-mobile"}
                         name={name}>
              <div className="modal-room-mobile__descr" dangerouslySetInnerHTML={{__html: descr}}></div>
              <Slider {...settingsMobile}>
                {
                  photos.map((photo, index) => {
                    return (
                      <div key={index}>
                        <div className="modal-room-mobile__image"
                             style={{backgroundImage: `url('${photo.img}')`}}></div>
                      </div>
                    )
                  })
                }
              </Slider>

              {prices.length ? <div className="modal-room-mobile__prices">
                <h4>{t('Ціни')}</h4>
                <ul>
                  {
                    prices.map((price, index) => {
                      const {dateStart, dateEnd, priceWeekdays, priceWeekend} = price;
                      const formatStartDate = moment(dateStart).format('DD.MM.YYYY');
                      const formatEndDate = moment(dateEnd).format('DD.MM.YYYY');

                      return (
                        <li key={index}>
                          <span
                            className="modal-room-mobile__date">{formatStartDate} {t("р[рік]")} - {formatEndDate} {t("р[рік]")}</span>
                          <div>
                            <span className="modal-room-mobile__summary">{priceWeekdays}</span>
                            <span className="modal-room-mobile__currency"> {t("грн")}</span>
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
              </div> : null}

              <div className="modal-room-mobile__properties">
                <h4><IconProps/> {t('Зручності в номері')}</h4>
                <ul>
                  {facilities.map((item, index) => {
                    return <li key={index}>- {item.title}</li>
                  })}
                </ul>
              </div>

              <div className="modal-room-mobile__info" style={{paddingLeft: 15}}>
                <IconSizes/>
                <div>
                  <h4>{t('Розмір номера')}</h4>
                  <span>{currentRoom.area} {t('м')}</span>
                </div>

              </div>

              {currentRoom.location && <div className="modal-room-mobile__info" style={{paddingLeft: 15}}>
                <IconLocation/>
                <div>
                  <h4>{t('Розташування')}</h4>
                  <span>{currentRoom.location}</span>
                </div>
              </div>}


            </ModalMobile> : <PreloaderList/>}
        </MediaQuery>

        {/*FOR DESKTOP*/}
        <MediaQuery minWidth={900}>
          <Modal closePath="/" onClickClose={this.goBack} className={"modal-room"}>


            {!isFetching ? <Aux>
                <Slider ref={c => (this.slider = c)} {...settings}>
                  {rooms.map((room, index) => {
                    const {name = "", descr = "", prices = [], facilities = [], photos = [], area, location} = room;

                    return (
                      <div key={index}>


                        <div className="modal-room__wrapper">
                          {photos.length ? <DoubleCarouselsLayout photos={photos}/> : null}

                          <div className="modal-room__data">
                            <Scrollbars autoHeightMax="auto" autoHeight={false}>
                              <div className="modal-room__text">
                                <h1 className="modal-room__title">{name}</h1>
                                <div className="modal-room__description" dangerouslySetInnerHTML={{__html: descr}}></div>
                              </div>


                              <div className="modal-room__properties">

                                <h4><IconProps/> {t('Зручності в номері')}</h4>
                                <ul className="modal-room-grid">
                                  {facilities.map((item, index) => {
                                    return <li key={index}>- {item.title}</li>
                                  })}
                                </ul>

                                <div className="modal-room-grid">

                                  {area &&
                                    <div className="modal-room-mobile__info">
                                      <IconSizes/>
                                      <div>
                                        <h4>{t('Розмір номера')}</h4>
                                        <span>{area} {t('м')}</span>
                                      </div>
                                    </div>
                                  }

                                  {
                                    location && <div className="modal-room-mobile__info">
                                      <IconLocation/>
                                      <div>
                                        <h4>{t('Розташування')}</h4>
                                        <span>{location}</span>
                                      </div>
                                    </div>
                                  }

                                </div>


                              </div>


                              {prices.length ? <div className="modal-room__prices">

                                <h4>{t('Ціни')}</h4>
                                {/*package:  packageHotel*/}
                                <ul>
                                  {
                                    prices.map((price, index) => {
                                      const {dateStart, dateEnd, priceWeekdays, priceWeekend} = price;
                                      const formatStartDate = moment(dateStart).format('DD.MM.YYYY');
                                      const formatEndDate = moment(dateEnd).format('DD.MM.YYYY');
                                      return (
                                        <li key={index}>
                                                                                    <span
                                                                                      className="modal-room__date">{formatStartDate} {t('р[рік]')} - {formatEndDate} {t('р[рік]')}</span>
                                          <div>
                                                                                        <span
                                                                                          className="modal-room__summary">{new Intl.NumberFormat('uk', {
                                                                                          minimumFractionDigits: 0,
                                                                                          currency: 'UAH'
                                                                                        }).format(priceWeekdays)}</span>
                                            <span
                                              className="modal-room__currency"> {t('грн')}</span>
                                          </div>
                                        </li>
                                      )
                                    })
                                  }
                                </ul>

                              </div> : null}

                            </Scrollbars>

                            <div className="modal-room__nav">
                              <div className="modal-room__prev" onClick={this.previous}>
                                {t('Попередній номер')}
                                <i></i>
                              </div>
                              <a href={`https://bukovel24.com${language}/hotels/bukovel-apart`} target="_blank">
                                <Button>{t('Бронювати')}</Button>
                              </a>
                              <div className="modal-room__next" onClick={this.next}>
                                {t('Наступний номер')}
                                <i></i>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </Slider>
              </Aux> :
              <PreloaderList/>
            }
          </Modal>
        </MediaQuery>

      </Aux>

    )
  }
}


const mapStateToProps = (state, props) => {
  const {data = {}, isFetching} = state.main;
  const {rooms = []} = data;

  const {roomId} = props.match.params;


  const findRoomById = rooms.filter((room) => {
    return room.id === Number(roomId)
  });


  let currentIndex = 0;
  rooms.map((room, index) => {
    if (room.id === Number(roomId)) {
      currentIndex = index;
    }
  });


  return {
    rooms,
    currentRoom: findRoomById[0],
    currentIndex,
    isFetching
  }
};


export default withRouter(connect(mapStateToProps)(withI18n()(ModalRoom)));
